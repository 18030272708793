import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink, ExternalLink } from '../../../components';
import { navigationConfig } from '../../../config/configNavigation';

import css from './TopbarNavigation.module.css';

const TopbarNavigation = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <ul className={css.navigationList}>
        {navigationConfig
          .filter(c => c.showInTopbar)
          .map(item => {
            return (
              <li key={item.key} className={css.navigationItem}>
                <NamedLink
                  className={css.navigationLink}
                  name="SearchPage"
                  to={{ search: `pub_category=${item.key}` }}
                >
                  {item.label}
                </NamedLink>
              </li>
            );
          })}
        <li className={css.navigationItem}>
          <ExternalLink className={css.navigationLink} href="https://medium.com/lovecoda">
            <FormattedMessage id="TopbarNavigation.externalLink" />
          </ExternalLink>
        </li>
      </ul>
    </div>
  );
};

TopbarNavigation.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarNavigation.propTypes = {
  rootClassName: string,
  className: string,
};

export default TopbarNavigation;

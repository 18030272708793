import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { isUserSeller } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentUser, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isSeller = isUserSeller(currentUser);
  const tabs = isSeller
    ? [
        {
          text: <FormattedMessage id="UserNav.yourListings" />,
          selected: currentPage === 'ManageListingsPage',
          linkProps: {
            name: 'ManageListingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.profileSettings" />,
          selected: currentPage === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.newListing" />,
          linkProps: {
            name: 'NewListingPage',
          },
        },
      ]
    : [
        {
          text: <FormattedMessage id="UserNav.profileSettings" />,
          selected: currentPage === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsPage',
          },
        },
        {
          text: <FormattedMessage id="UserNav.accountSettings" />,
          selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
          disabled: false,
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
      ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentUser: propTypes.currentUser,
  currentPage: string.isRequired,
};

export default UserNav;

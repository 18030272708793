import React from 'react';
import { USER_TYPE_BUYER, USER_TYPE_SELLER } from '../util/types';

const renderBuyerExtraLabel = (
  <>
    <strong>Buying</strong> products or services
  </>
);
const renderSellerExtraLabel = (
  <>
    <strong>Listing</strong> my products or services for sale
  </>
);

/* 
  User types are used for determining if a user is a seller or a buyer.
*/
export const userTypes = [
  {
    option: USER_TYPE_BUYER,
    label: 'Buyer',
    extraLabel: renderBuyerExtraLabel,
  },
  {
    option: USER_TYPE_SELLER,
    label: 'Seller',
    extraLabel: renderSellerExtraLabel,
  },
];
